import React, { useState } from "react";

// import honeyComb from "../../assets/company/team/team-background.jpg";
import teamPic from "../../assets/company/team/team.png";

require('../../css/company/team.css');

import alex from '../../assets/company/team/alex.png';
import dawn from '../../assets/company/team/dawn.png';
import hayden from '../../assets/company/team/hayden.png';
import joel2 from '../../assets/company/team/joel.png';
import joel from '../../assets/company/team/joel-2.png';
import nichole from '../../assets/company/team/Nicole_Headshot_low_res.jpg';
import thibaud from '../../assets/company/team/thibaud.jpg';
import phil from '../../assets/company/team/phil.png';
import stan from '../../assets/company/team/stan.png';
import veger from '../../assets/company/team/veger.png';
import pete from '../../assets/company/team/pete.png';

const team = [
    { name: 'Joel Sercel, PhD.', desc: 'Founder & CEO', img: joel, in: 'https://www.linkedin.com/in/joelsercel/' },
    { name: 'Hayden Burgoyne, PhD.', desc: 'Director of Engineering', img: hayden, in: 'https://www.linkedin.com/in/hayden-burgoyne/' },
    { name: 'Nicole Shumaker', desc: 'VP of Business Development', img: nichole, in: 'https://www.linkedin.com/in/nicoleshumaker/' },
    { name: 'Alex Pilmer ', desc: 'Chief Business Officer', img: alex, in: 'https://www.linkedin.com/in/alexpilmer/' },
    { name: 'Philip Wahl', desc: 'Head of R&D Engineering', img: phil, in: 'https://www.linkedin.com/in/philip-wahl/' },
    { name: 'Thibaud Talon', desc: 'Chief of Mechatronics', img: thibaud, in: 'https://www.linkedin.com/in/thibaudtalon/' },
]

const board = [
    { name: 'Dawn Harms', img: dawn, in: 'https://www.linkedin.com/in/dawn-harms-b217a43/' },
    { name: 'Dr. Stanley G. Love', img: stan, in: 'https://www.linkedin.com/in/stan-love-8100bb8/' },
    { name: 'Dr. Joel Sercel', img: joel2, in: 'https://www.linkedin.com/in/joelsercel/' },
    { name: 'Paul Veger', img: veger, in: 'https://www.linkedin.com/in/paulveger/' },
    { name: 'Pete Garretson', img: pete, in: 'https://www.linkedin.com/in/petergarretson/' },

]


const Team = (props) => {
    let dKey = 1;
    const desktopDisplay = (
        // style={{ "backgroundImage": `url(${honeyComb})` }} 
        <div id="team" className="capa-container">
            <h1>TEAM</h1>
            <div className="container" >
                {team.map((item) => {
                    ++dKey;
                    let extra = dKey === 4 ? { "padding": "0 25%" } : {}
                    return (
                        <a target="_blank" href={item.in} className="sub-container"
                            key={dKey} style={extra} >
                            <div><img src={item.img} ></img></div>
                            <h2 style={{ "textWrap": "noWrap" }} >{item.name}</h2>
                            <p>{item.desc}</p>
                        </a>
                    )
                })}
            </div>
            <div style={{ "width": "100%" }} >
                <img style={{ "width": "100%" }} src={teamPic} ></img>
            </div>
            <h1>BOARD OF DIRECTORS</h1>
            <div className="board-container" >
                {board.map((item) => {
                    return (
                        <a key={dKey++} className="sub-container"
                            target="_blank" href={item.in} >
                            <div><img src={item.img} ></img></div>
                            <h2>{item.name}</h2>
                        </a>
                    )
                })}
            </div>
        </div>
    )

    let mKey = -1;
    const mobileDisplay = (
        <div id="team" className="capa-container" >
            <div>
                <h1>TEAM</h1>
                <div className="container" >
                    {team.map((item) => {
                        return <a className="sub-container" key={mKey++}
                            target="_blank" href={item.in}>
                            <div><img src={item.img} ></img></div>
                            <h2>{item.name}</h2>
                            <p>{item.desc}</p>
                        </a>
                    })}
                </div>
            </div>
            <div>
                <h1>BOARD OF DIRECTORS</h1>
                <div className="container" >
                    {board.map((item) => {
                        return <a className="sub-container" key={mKey++}
                            target="_blank" href={item.in} >
                            <div><img src={item.img} ></img></div>
                            <h2>{item.name}</h2>
                        </a>
                    })}
                </div>
            </div>
        </div>
    )

    const disp = props.shouldLoadDesktop ? desktopDisplay : mobileDisplay;

    return (
        <>
            {disp}
        </>
    )
}

export default Team;